/**
 *
 * IndexPage
 *
 *
 */

 import React, { useEffect } from 'react';
 import styled from 'styled-components';
 
 import { graphql } from "gatsby";
 
 
 import ContentEngine from 'components/content/ContentEngine';
 import SectionHeadline from 'components/elements/SectionHeadline';
 
 
 const TeeBox = ({ data }) => {
   useEffect(() => {
 
   });
   console.log(data);
   const { contentEngine } = data.contentEngine;
   
   return (
     <Container>
      <Headline greenText tenTen>The Tee Box</Headline>
      <ContentEngine blocks={contentEngine}/>
     </Container>
   );
 };
 
TeeBox.propTypes = {
 
 };
 
 export default TeeBox;
 
 const Container = styled.main`
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: flex-start;
   background-color: white;
   padding-bottom: 6em;
   padding-top: 4em;
 `;

 const Headline = styled(SectionHeadline)`
  margin-bottom: 4em;
 `;
 
 export const pageQuery = graphql`
   {
     contentEngine: craftTeeBoxTeeBoxEntry  {
        contentEngine {
          ... on Craft_contentEngine_richText_BlockType {
            typeHandle
            body
          }
          ... on Craft_contentEngine_blockQuote_BlockType {
            typeHandle
            citation
            quote
          }
          ... on Craft_contentEngine_videoEmbed_BlockType {
            typeHandle
            videoUrl
          }
          ... on Craft_contentEngine_headline_BlockType {
            typeHandle
            text
          }
          ... on Craft_contentEngine_lineBreak_BlockType {
            typeHandle
            icon {
              url
            }
          }
          ... on Craft_contentEngine_pullQuote_BlockType {
            typeHandle
            quote
          }
          ... on Craft_contentEngine_cta_BlockType {
            typeHandle
            textColor
            buttonColor
            buttonLink
            buttonText
            newTab
          }
          ... on Craft_contentEngine_ctaTwoUp_BlockType {
            typeHandle
            ctaBlock {
              ... on Craft_ctaBlock_BlockType {
                textColor
                buttonColor
                buttonLink
                buttonText
                newTab
              }
            }
          }
          ... on Craft_contentEngine_photoGallery_BlockType {
            typeHandle
            slide {
              ... on Craft_slide_BlockType {
                id
                image {
                  url
                  focalPoint
                }
              }
            }
          }
          ... on Craft_contentEngine_imageOneUp_BlockType {
            typeHandle
            image {
              url
              focalPoint
            }
          }
          ... on Craft_contentEngine_imageTwoUp_BlockType {
            typeHandle
            images {
              ... on Craft_images_BlockType {
                id
                image {
                  url
                  focalPoint
                }
              }
            }
          }
        }
      }
   }
 `;